import { getStation } from "@/utils/DataUtil";

/** 抽离订单类 */
export default class Order{
    constructor(data){
      this.data = data || {};
    }
    get trip_info(){
      let {
        trip_id,
        car_id,
        trip_start_time,
        trip_created_time,
        trip_end_time,
        trip_type_ext,
        trip_points,
        trip_now_time,
        route,
        trip_updated_time,
        car_time
      } = this?.data;
      return {
        trip_id,
        car_id,
        trip_start_time,
        trip_created_time,
        trip_end_time,
        trip_points,
        trip_now_time,
        trip_updated_time,
        trip_type_ext,
        route,
        car_time,
        TRIP_POINT_TYPE_UP_STATION: getStation(trip_points)?.TRIP_POINT_TYPE_UP_STATION,
        TRIP_POINT_TYPE_DOWN_STATION: getStation(trip_points)?.TRIP_POINT_TYPE_DOWN_STATION,
      }
    }
    get trip_type() {
      return this?.data?.trip_type;
    }
    get trip_type_ext(){
      return this?.data?.trip_type_ext;
    }
    get trip_points(){
      return this?.data?.trip_points;
    }
    get order_status(){
      return this?.data?.state;
    }
    get order_status_ext(){
      return this?.data?.state_ext;
    }
    get driving_route(){
      return this?.data?.route?.driving_route || {};
    }
    get trip_id(){
      return this?.data?.trip_id;
    }
    get user(){
      return this?.data?.user;
    }
    get car_state(){
      return this?.data?.car?.state;
    }
    get reserve_trip(){
      return this?.data?.reserve_trip;
    }
  }