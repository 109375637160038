import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from '@/components/common/slider/index';
import {
  pollOrder, // 订单轮询
} from '@/utils/api';
import { SaveEtdType } from '@/store/action/type';
// 订单完成组件
import OrderComplate from '@/components/common/OrderComplate';
// 送乘客下车点到达组件
import DriveOff from '@/components/common/DriveOff';
// 到达接客上车点组件
import GetPassenger from '@/components/common/getPassenger/index';
// 订单取消组件
import CancelOrder from '@/components/common/cancelOrder';
// 等待乘客组件
import WaitPassenger from '@/components/common/WaitPassenger';
// 容器组件
import OrderCard from '@/components/common/OrderCard';
// 用户信息组件
import UserInfo from '@/components/common/userInfo';
import CarCheck from '@/components/common/carCheck';
// 订单状态配置
import { OrderStatus } from '@/constant/order';
import RouteLoading from '@/components/common/RouteLoading';
import MtourComingTips from "@/components/common/Mtour/index";
import OrderList from "@/components/common/OrderList/index";

import MtourAccept from '@/components/common/MtourAccept';

import { RoboTaxiService } from './roboTaxiService';
const { 
  MTOUR_ACCEPT,
  CAR_START, 
  WAIT_PASSENGER, 
  TRANS_PASSENGER, 
  ARRIVE_DESTINATION, 
  PASSENGER_TIMEOUT, 
  PASSENGER_CANCEL,
  DRIVER_CANCEL, 
  DRIVER_OFFLINE 
} = OrderStatus;

function isShowCancel(order_status) {
  return order_status === PASSENGER_TIMEOUT ||
  order_status === PASSENGER_CANCEL ||
  order_status === DRIVER_CANCEL ||
  order_status === DRIVER_OFFLINE
}

function getComponentShow(order_status, status) {
  return order_status === status;
}

window.__robotaxi_timer = 0;
export default function Home() {
  const OS = useSelector((state) => state.order.OrderStatus);
  const TRIP_INFO = useSelector((state) => state.order.TRIP_INFO);
  const menuType = useSelector((state) => state.slider.CurTab);
  const routeLoadingFlag = useSelector((state) => state.map.routeLoading);
  const hasCheck = useSelector((state) => state.check.hasCheck);
  let pollData = null;
  const dispatch = useDispatch();
  const pollOrderInfo = async () => {
    let {data} = await pollOrder();
    return data;
  };
  const createService = () => {
    let RoboService = new RoboTaxiService(dispatch);
    window.__robotaxi_timer = setInterval(async () => {
      pollData = await pollOrderInfo();
      RoboService.changeOrderStatus(Object.assign({}, pollData));
    }, 1000);
  }
  useEffect(() => {
    window.getEtd = function (data) {
      dispatch({
        type: SaveEtdType.saveETD,
        data: data
      });
    }
  }, []);

  useEffect(()=>{
      createService();
      return ()=> {
        clearInterval(window.__robotaxi_timer);
      }
  },[]);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Slider />
      { menuType === 'user' &&  <UserInfo />}
      { menuType === 'order' &&  <OrderList TRIP_INFO={TRIP_INFO} />}
      { routeLoadingFlag && <RouteLoading />}
      
      { isShowCancel(OS) && <CancelOrder TRIP_INFO={TRIP_INFO} />}

      { getComponentShow(OS, MTOUR_ACCEPT) && (
        <OrderCard>
          <MtourAccept TRIP_INFO={TRIP_INFO}></MtourAccept>
        </OrderCard>
      )}

      { getComponentShow(OS, CAR_START) && (
        <OrderCard>
          <GetPassenger TRIP_INFO={TRIP_INFO} />
        </OrderCard>
      )}
      { getComponentShow(OS, WAIT_PASSENGER) && (
        <OrderCard>
          <WaitPassenger TRIP_INFO={TRIP_INFO} />
        </OrderCard>
      )}
      { getComponentShow(OS, TRANS_PASSENGER) && (
        <OrderCard showCancel={false}>
          <DriveOff TRIP_INFO={TRIP_INFO} />
        </OrderCard>
      )}
      { getComponentShow(OS, ARRIVE_DESTINATION) && 
        <OrderComplate TRIP_INFO={TRIP_INFO} />
      }
      {hasCheck && <CarCheck></CarCheck>}
      <MtourComingTips TRIP_INFO={TRIP_INFO}/>
    </div>
  );
}
